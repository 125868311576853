
<section>
  <form fxLayout="column" fxLayoutAlign="center center" (ngSubmit)="formReplaceNft(f)" #f="ngForm">
    <div fxLayout="row" fxLayoutAlign=" center center">
      <div fxLayout="column" fxLayoutAlign=" center center" style="width:220px;">
    
      <div class="row"  fxFlex fxLayout="row" fxAlign="flex-center">
        <div class="col col-6">
          <mat-form-field class="        " > 
            <mat-label>Select Chain</mat-label>
            <mat-select ngModel name="chain" >
              <mat-option *ngFor="let c of chains$ | async" [value]="c.name">{{c.name | uppercase}} | {{c.type}}</mat-option> 
            </mat-select> 
          </mat-form-field>
        </div>

        <div class="col col-6" id="button">
          <button mat-raised-button mat-icon-button color="accent" type="submit">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>

        <mat-form-field style="min-width:375px" appearance="outline">
          <mat-label>Ethereum Address</mat-label>
          <input matInput type="text" name="nftAddress" ngModel placeholder="NFT" minlength="42" required />
        </mat-form-field>

      </div> 
    </div>
   
  </form>
</section>