<div class="row parent">

    <div class="col post-btn">
        <button (click)="changeState('state2')" class="btn btn-gray pull-left">View</button><br />
        <button (click)="changeState('state1')" class="btn btn-gray pull-left">Close</button>
    </div>
    <div class="col post">
        <table class="table">
            <caption>Posts</caption>
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Category</th>
                    <th>Title</th>
                </tr>
            </thead>
            <tr *ngFor="let post of posts  ">

                <td>{{post.date}}</td>
                <td>{{post.cat3}}</td>
                <td>{{post.title}}</td>
            </tr>
        </table>
        <app-blog (blogClicked)="onBlogClick(blog)" *ngFor="let blog of blogs" [blogName]="blog"></app-blog>
        <app-animation [currentState]="toState"></app-animation>
    </div>

</div>