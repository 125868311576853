<div class="nfts">
  <section>
    <!--   input: chain + address -->
    <app-nft-add>
    </app-nft-add>
  </section>

  <section>
    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="nftData">
      <app-chaindata [nftData]="nftData"></app-chaindata>
    </div>
  </section>

  <section>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1px" *ngIf="nfts">
      <app-nft *ngFor="let nft of nftData.nfts" [inputNft]="nft" [nftData]="nftData"></app-nft>

    </div>
  </section>
</div>