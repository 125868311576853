 
<h2><span class="daily-tech monoton text-box">OUR DAILY TECH</span></h2>
<hr class="bar-white" />
 
<mat-tab-group  mat-align-tabs="center">
  <mat-tab color="primary" label="All Categories">
    <div class="temp">
      <mat-spinner *ngIf="blogsLoading" class="centerSpinner"></mat-spinner>
      <ul *ngFor="let blog of blogs" class="view-blog">
        <mat-card (click)="openBlogModal(blog.id)">
          <li>
            <span [innerHTML]="blog.cat3 | safeHtml" class="category"></span>
            <button
              class="btnModal btnCat"
              mat-stroked-button
              (click)="viewAllBlogs(0)"
            >
             View All
            </button>
            <!-- <button
              class="btnModal"
              mat-stroked-button
              (click)="openBlogModal(blog.id)"
            >
              Reader
            </button> -->

            <!-- <button
              class="btnModal"
              mat-stroked-button
              (click)="viewBlog(blog.id)"
            >
              Print
            </button> -->
            <span class="date" [innerHTML]="blog.date | safeHtml"></span>
          </li>
          <h6 [innerHTML]="blog.title | safeHtml"></h6>
          <p class="blog-post" [innerHTML]="blog.post | safeHtml"></p>
        </mat-card>
      </ul>
    </div>
  </mat-tab>

  <mat-tab color="primary" label="Web Dev Affairs" >
    <div class="temp">
      <ul *ngFor="let blog of blogsWeb" class="view-blog">
        <!-- <mat-card (click)="viewBlog(blog.id)"> -->
        <mat-card (click)="openBlogModal(blog.id)">
          <li>
            <span [innerHTML]="blog.cat3 | safeHtml" class="category"></span>
            <button
              class="btnModal btnCat"
              mat-stroked-button
              (click)="viewAllBlogs(blog.cat3)"
            >          View All
          </button>
          <!-- <button
            class="btnModal"
            mat-stroked-button
            (click)="openBlogModal(blog.id)"
          >
            Reader
          </button> -->
          <!-- <button
            class="btnModal"
            mat-stroked-button
            (click)="viewBlog(blog.id)"
          >
            Print
          </button> -->
          <span class="date" [innerHTML]="blog.date | safeHtml"></span>
        </li>
        <h6 [innerHTML]="blog.title | safeHtml"></h6>
        <p class="blog-post" [innerHTML]="blog.post | safeHtml"></p>
      </mat-card>
      </ul>
    </div>
  </mat-tab>

  <mat-tab color="primary" label="Musing Blockchain">
    <div class="temp">
      <ul *ngFor="let blog of blogsBlockchain" class="view-blog">
        <mat-card (click)="openBlogModal(blog.id)">
          <li>
            <span [innerHTML]="blog.cat3 | safeHtml" class="category"></span>
            <button
              class="btnModal btnCat"
              mat-stroked-button
              (click)="viewAllBlogs(blog.cat3)"
            >            View All
          </button>
          <!-- <button
            class="btnModal"
            mat-stroked-button
            (click)="openBlogModal(blog.id)"
          >
            Reader
          </button> -->
          <!-- <button
            class="btnModal"
            mat-stroked-button
            (click)="viewBlog(blog.id)"
          >
            Print
          </button> -->
          <span class="date" [innerHTML]="blog.date | safeHtml"></span>
        </li>
        <h6 [innerHTML]="blog.title | safeHtml"></h6>
        <p class="blog-post" [innerHTML]="blog.post | safeHtml"></p>
      </mat-card>
      </ul>
    </div>
  </mat-tab>

  <mat-tab color="primary" label="A.I.Now.">
    <div class="temp">
      <ul *ngFor="let blog of blogsAI" class="view-blog">
        <mat-card (click)="openBlogModal(blog.id)">
          <li>
            <span [innerHTML]="blog.cat3 | safeHtml" class="category"></span>
            <button
              class="btnModal btnCat"
              mat-stroked-button
              (click)="viewAllBlogs(blog.cat3)"
            >  View All
          </button>
          <!-- <button
            class="btnModal"
            mat-stroked-button
            (click)="openBlogModal(blog.id)"
          >
            Reader
          </button> -->
          <button
            class="btnModal"
            mat-stroked-button
            (click)="viewBlog(blog.id)"
          >
            Print
          </button>
          <span class="date" [innerHTML]="blog.date | safeHtml"></span>
          <hr class="bar-title" />
          <br />
        </li>
        <h6 [innerHTML]="blog.title | safeHtml"></h6>
        <p class="blog-post" [innerHTML]="blog.post | safeHtml"></p>
      </mat-card>
      </ul>
    </div>
  </mat-tab>

  <mat-tab color="primary" label="Sociology Tomorrow!">
    <div class="temp">
      <ul *ngFor="let blog of blogsSoc" class="view-blog">
        <mat-card (click)="openBlogModal(blog.id)">
          <li>
            <span [innerHTML]="blog.cat3 | safeHtml" class="category"></span>
            <button
              class="btnModal btnCat"
              mat-stroked-button
              (click)="viewAllBlogs(blog.cat3)"
            >  View All
          </button>
          <!-- <button
            class="btnModal"
            mat-stroked-button
            (click)="openBlogModal(blog.id)"
          >
            Reader
          </button> -->
          <!-- <button
            class="btnModal"
            mat-stroked-button
            (click)="viewBlog(blog.id)"
          >
            Print
          </button> -->
          <span class="date" [innerHTML]="blog.date | safeHtml"></span>
        </li>
        <h6 [innerHTML]="blog.title | safeHtml"></h6>
        <p class="blog-post" [innerHTML]="blog.post | safeHtml"></p>
      </mat-card>
      </ul>
    </div>
  </mat-tab>
  <mat-tab color="primary" label="Quantum Data">
    <div class="temp">
      <ul *ngFor="let blog of blogsQuantum" class="view-blog">
        <mat-card (click)="openBlogModal(blog.id)">
          <li>
            <span [innerHTML]="blog.cat3 | safeHtml" class="category"></span>
            <button
              class="btnModal btnCat"
              mat-stroked-button
              (click)="viewAllBlogs(blog.cat3)"
            >    View All
          </button>
          <!-- <button
            class="btnModal"
            mat-stroked-button
            (click)="openBlogModal(blog.id)"
          >
            Reader
          </button> -->
          <!-- <button
            class="btnModal"
            mat-stroked-button
            (click)="viewBlog(blog.id)"
          >
            Print
          </button> -->
          <span class="date" [innerHTML]="blog.date | safeHtml"></span>
        </li>
        <h6 [innerHTML]="blog.title | safeHtml"></h6>
        <p class="blog-post" [innerHTML]="blog.post | safeHtml"></p>
      </mat-card>
      </ul>
    </div>
  </mat-tab>

  <!-- <mat-tab color="primary" label="Quantum Data">
        <table mat-table class="mat-elevation-z8" *ngFor="let blog of blogs">

            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef> Date </th>
                <td mat-cell *matCellDef="let blog"> {{blog.date}}</td>
            </ng-container>

            <ng-container matColumnDef="cat3">
                <th mat-header-cell *matHeaderCellDef> Category </th>
                <td mat-cell *matCellDef="let blog">{{blog.cat3}}</td>
            </ng-container>

            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef> Title </th>
                <td mat-cell *matCellDef="let blog">{{blog.title}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </mat-tab> -->
</mat-tab-group>
 