<hr>
<h2> <span class="daily-tech monoton text-box">OUR DAILY TECH</span> </h2>
<hr>
<mat-tab-group *ngIf="!(ongoingWriting$ | async)" center>

  <mat-tab label="New Session">
    <!-- <app-new-writing (writingStart)="ongoingWriting = true"></app-new-writing> -->
    <app-new-writing ></app-new-writing>
  </mat-tab>
  <mat-tab label="Past Session Stats">
    <app-past-writings></app-past-writings>
</mat-tab>
<mat-tab label="Statistics Grid">
  <app-grid-writings></app-grid-writings>
</mat-tab>
</mat-tab-group>
<app-current-writing *ngIf="ongoingWriting$ | async" ></app-current-writing>
