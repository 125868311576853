
<div class="alert alert-success" *ngIf='message'>{{message}}</div>

<div class="container">
    <table class="table">
<caption>Posts</caption>
<thead>
    <tr >
        <th>Delete</th>
        <th>ID</th>
        <th>Date</th>
        <th>Category</th>
        <th>Title</th>  
    </tr>
</thead>
<tr *ngFor="let post of posts  ">
    <td><button mat-raised-button color="warning" (click)="deletePost(post.id)"><small>Delete</small></button></td>
    <td><button mat-raised-button color="basic" (click)="updatePost(post.id)"><small>Edit</small></button></td>
    <td>{{post.id}}</td>
    <td>{{post.date}}</td>
    <td>{{post.cat3}}</td>
    <td>{{post.title}}</td> 
</tr>
</table>
<div class="row">
    <button mat-raised-button color="basic" (click)="addPost()" >Add</button>
</div>
</div>