<div class="menu">
  <footer>
  <p>        
    <strong>&copy;</strong>
    <a
      class="footer-text"
      href="https://thomasmaestas.net"
      target="_blank"
      title="Contact Information: thomasmaestas.net/"
    >
      thomasmaestas.net </a
    >&nbsp;  &nbsp;

    <a title="github" href="https://github.com/thomasm1" target="_blank">
      <i class="fa fa-github"></i>
    </a>&nbsp;  &nbsp;


    <a title="twitter" href="https://twitter.com/ThomasMaestas" target="_blank">
      <i class="fa fa-twitter"></i>
    </a>&nbsp;   &nbsp;


    <a
      title="facebook"
      href="https://www.facebook.com/thomas.m.maestas"
      target="_blank"
    >
      <i class="fa fa-facebook"></i>
    </a>&nbsp;   &nbsp;


    <a
      title="linkedin"
      href="https://linkedin.com/in/thomasmaestas"
      target="_blank"
    >
      <i class="fa fa-linkedin"></i>
    </a>
    &nbsp;  &nbsp;
    <a  href="https://blog.ourdailytech.net" target="_blank" class="footer-text" > dailytech 1.0&nbsp; &nbsp;  </a >
  </p>
  
</footer>
</div>
